//*
//*
//*
//*
// Global strings for the site

// Name of the dealership
export const DEALERSHIP_NAME = "Coast Corvette"

// Address for the dealership
export const ADDRESS = "828 W. Vermont Ave. Anaheim, CA 92805"

// Phone number for the dealership
export const PHONE_NUMBER = "(714) 535-8555"

// Email address for the dealership
export const EMAIL = "sales@coastcorvette.com"

// Hours of operation as shown in footer
export const HOURS = [
  { day: "M-F", hours: "9AM - 5PM" },
  { day: "Sat", hours: "9AM - 3PM" },
  { day: "Sun", hours: "Closed" },
]

// This is the embed url from Google Maps, to generate a new one, go to Google Maps, search for your location, click the share button, and copy the embed url
export const MAP_URL =
  "https://www.google.com/maps/dir//828+W+Vermont+Ave,+Anaheim,+CA+92805/@33.8204814,-117.9218712,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x80dcd62b5c908f19:0x9cfe8d58073175b6!2m2!1d-117.9196825!2d33.820477!3e0"

export const DISCLAIMER = `The estimated monthly payments and estimated down
payments listed on this inventory page are based on a 720 credit
score and a 72 month term with $100,000 annual gross income.
Actual terms may vary based on individual creditworthiness, loan
amount, and length of term. Prices listed may exclude additional
fees and taxes. Please contact us for more information on
financing options and terms.
`
//*
//*
//*
//*
// Homepage strings

// Tagline for the homepage, this should be a slogan or a catchy phrase.
export const TAGLINE = "Serving Corvette and Classic Car Enthusiasts Since 1978"

// This is the link for the google maps directions link. To generate one go to google maps, enter the location, click directions, and copy the link in the address bar. This way the user will go to the directions page from their location to the dealership location
export const MAP_LINK_URL =
  "https://www.google.com/maps/dir//Coast+Corvette,+828+W+Vermont+Ave+A,+Anaheim,+CA+92805/@33.8106808,-117.9057455,15z/data=!4m9!4m8!1m0!1m5!1m1!1s0x80dcd62b5b9a350b:0xbaa2e0afbdc28155!2m2!1d-117.9198126!2d33.8203984!3e0?entry=ttu"

// This is dummy data. Replace with dealership testimonials.
export const TESTIMONIALS = [
  {
    name: "John Doe",
    title: "Satisfied Customer",
    testimonial:
      "The dealership was very helpful, and the staff were friendly and knowledgeable. I got a great deal on my new car, and the process was seamless and quick.",
    rating: 5,
  },
  {
    name: "Jane Smith",
    title: "Happy Buyer",
    testimonial:
      "The customer service here is excellent. They helped me find the perfect car for my needs and budget. Their transparency and honesty were much appreciated.",
    rating: 4,
  },
  {
    name: "Alice Johnson",
    title: "Long-time Customer",
    testimonial:
      "I have been coming to this dealership for years, and they never disappoint. They have a wide variety of cars and are always upfront about costs and features.",
    rating: 5,
  },
  {
    name: "Bob Williams",
    title: "First-time Buyer",
    testimonial:
      "As a first-time car buyer, I appreciated the patience and guidance the dealership provided. They made sure I understood everything and didn’t pressure me.",
    rating: 4,
  },
  {
    name: "Charlie Brown",
    title: "Returning Customer",
    testimonial:
      "This is the third car I’ve bought from this dealership, and every experience has been great. The staff is friendly, and their after-sales service is superb.",
    rating: 5,
  },
  {
    name: "Donna Davis",
    title: "Satisfied Customer",
    testimonial:
      "They made the process of trading in my old car for a new one so easy. They gave me a fair price and handled all the paperwork efficiently.",
    rating: 5,
  },
  {
    name: "Edward Johnson",
    title: "Happy Buyer",
    testimonial:
      "The dealership was able to answer all my questions and provide me with all the information I needed. Their level of service and commitment is commendable.",
    rating: 4,
  },
  {
    name: "Fiona White",
    title: "First-time Buyer",
    testimonial:
      "Their selection of cars is excellent, and the staff was very patient and knowledgeable. I never felt rushed or pushed into making a decision.",
    rating: 4,
  },
  {
    name: "George Thompson",
    title: "Long-time Customer",
    testimonial:
      "This dealership always provides top-notch service. Their staff is efficient and friendly, and they have always been able to meet my needs and exceed my expectations.",
    rating: 5,
  },
  {
    name: "Helen Martin",
    title: "Returning Customer",
    testimonial:
      "The finance team at this dealership is incredible. They helped me secure a fantastic financing deal and made sure I understood all the terms and conditions.",
    rating: 5,
  },
]

//*
//*
//*
//*
// Contact Us Page strings
export const CONTACT_FORM_TITLE = "Contact Us"
export const CONTACT_FORM_SUBTITLE =
  "We'd love to hear from you. Contact us using the details below or fill out the form."
export const CON_CONTACT_TITLE = "Visit Our Showroom"

//*
//*
//*
//*
// Trade in Page strings
export const TRADE_FORM_TITLE = "We Buy Corvettes! Classic, Exotic, Luxury"
export const TRADE_FORM_SUBTITLE =
  "If you have a Corvette you would like to sell, please fill out the form below and we will get back to you as soon as possible."
export const TRADE_CONTACT_TITLE = "Visit Our In Person"

//*
//*
//*
//*
// Financing Page strings
export const FINANCING_TITLE = "Get Financing"
export const FINANCING_SUBTITLE =
  "We'd love to hear from you. Contact us using the details below or fill out the form for finance referrals."
export const FIN_CONTACT_TITLE = "Visit Our Showroom"
